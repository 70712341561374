import "./index.css";

function Footer() {
  return (
    <footer className="Footer">
      <p>Developed and designed by Caden Buckelew</p>
    </footer>
  );
}

export default Footer;
